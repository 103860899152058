export const BIKETOWN_COMPANY_1 = 'BIKETOWN';
export const BIKETOWN_COMPANY_2 = 'GBFS';
export const ZIPCAR_COMPANY = 'ZIPCAR';

export const BUS_LAYER = 'BUS_LAYER';
export const MAX_LAYER = 'MAX_LAYER';
export const WES_LAYER = 'WES_LAYER';
export const PSC_LAYER = 'PSC_LAYER';

export const STREETS_BASE_LAYER = 'streets';
export const AERIALS_BASE_LAYER = 'aerials';
export const ROUTES_BASE_LAYER = 'routes';
export const ROUTES_AERIALS_BASE_LAYER = 'routes + aerials';
export const THREE_DIMENSIONAL_LAYER = 'three dimensional';
export const ROUTES_THREE_DIMENSIONAL_LAYER = 'routes + three dimensional';
export const DEFAULT_BASE_LAYER = STREETS_BASE_LAYER;
export const REALTIME_VEHICLES_TOGGLED_KEY = 'REALTIME_VEHICLES_TOGGLED';
export const SHOW_MAP_STOPS_ZOOM_LEVEL = 16;
export const DUMMY_LNG = -122.68281340599061;
export const DUMMY_LAT = 45.52218740036735;

export const DEFAULT_BOUND_OPTIONS = {
  boundOptions: {
    animate: false,
    paddingBottomRight: [0, 0],
    paddingTopLeft: [0, 0]
  }
} as const;
